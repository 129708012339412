import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Page Component(s) */
import VipPage from "~components/pages/vip/vipPage/vipPage"

/* Import Global Components */
import Card from "~components/card/card"

/* Import Local Style(s) */
import "./vip-request.scss"

const VIPRequest = ({ location }) => {
  return (
    <VipPage
      title="Request VIP Access"
      header="Request VIP Access"
      location={location}
    >
      {/* <a
        href="https://forms.gle/BqAr6kt9PQYsd5SY8"
        target="_blank"
        rel="noreferrer"
        className="card-link"
      >
        <Card backgroundColor="var(--color-lightest-gray)">
          <p>Request Individual VIP Access</p>
        </Card>
      </a>
      <a
        href="https://forms.gle/N7hrQPBPqhKZaVns6"
        target="_blank"
        rel="noreferrer"
        className="card-link"
      >
        <Card backgroundColor="var(--color-lightest-gray)">
          <p>Request VIP Access for Institutional Patron Groups</p>
        </Card>
      </a> */}
      <p>
        For further information, kindly email{" "}
        <a
          href="mailto:vip@untitledartfairs.com"
          target="_blank"
          rel="noreferrer"
          style={{ display: "inline" }}
        >
          vip@untitledartfairs.com
        </a>
        .
      </p>

      <div className="links">
        <Link to="/vip/">Login</Link>
      </div>
    </VipPage>
  )
}

export default VIPRequest
